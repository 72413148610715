import clsx from "clsx";
import {graphql, navigate, useStaticQuery} from "gatsby";
import * as React from "react";
import {useState} from "react";
import {useFlexSearch} from "react-use-flexsearch";
import {Breadcrumb} from "../../components/Breadcrumb";
import {PageHeader} from "../../components/PageHeader";
import {SearchBar} from "../../components/SearchBar";
import {Seo} from "../../components/Seo";
import {Article} from "../../types";
import {generateRandomColor} from "../../util/colors";
import {ArticleBlock} from "../../components/ArticleBlock";
import {useGetArticleViews} from "../../hooks/useGetArticleViews";
import {useTrackSearch} from "../../hooks/useTrackSearch";

const Blog = (props: {
    location: any
}) => {
    const [sortByLatest, rawSetSortByLatest] = useState(true);
    const [searchQuery, setSearchQuery] = useState<string>(() => {
        // TODO REMOVE
        let searchBarQuery;

        if (props.location.state?.search) {
            searchBarQuery = props.location.state.search;
        }

        if (searchBarQuery !== undefined && searchBarQuery) {
            return (searchBarQuery);
        }

        if (props.location.state?.search) {
            return props.location.state?.search;
        }

        return "";
    });

    const setSearchQueryWrapper = (str) => {
        if (str) {
            navigate(`/blog/?search=${str}`, {
                replace: true
            });
        } else {
            navigate(`/blog/`, {
                replace: true
            });
        }
        setSearchQuery(str);
    }

    const setSortByLatest = (val: boolean) => {
        rawSetSortByLatest(val);
    };

    // TODO CHANGE STAGE TO PUBLISHED WHEN IN PRODUCTION WITH VARIABLE
    const data = useStaticQuery<GatsbyTypes.GetArticlesQuery>(graphql`
    query GetArticles {
      allGraphCmsArticle(filter: { stage: { eq: PUBLISHED } }) {
        edges {
          node {
            readTimeMinutes
            slug
            id
            contentImage {
              image {
                alt
                handle
                height
                width
                gatsbyImageData(placeholder: BLURRED, quality:70, width: 492, height: 188)
              }
            }
            title
            created
            excerpt
            category {
              label
            }
            tags {
              label
            }
          }
        }
      }
      localSearchPages {
        index
        store
      }
    }
  `);

    let slugs = [];

    for (let article of data.allGraphCmsArticle.edges) {
        slugs.push(article.node.slug);
    }

    const { isLoading, views } = useGetArticleViews(slugs);

    const searchResults = useFlexSearch(
        searchQuery,
        data.localSearchPages.index,
        data.localSearchPages.store
    );

    let articlesByYearsMap = new Map();

    if (sortByLatest) {
        if (searchQuery !== "") {
            searchResults.forEach((node: any) => {
                const year = new Date(node.created).getFullYear();

                if (!articlesByYearsMap.has(year)) {
                    articlesByYearsMap.set(year, []);
                }

                articlesByYearsMap.get(year).push(node);
            });
        } else {
            data.allGraphCmsArticle.edges.forEach((node: any) => {
                const year = new Date(node.node.created).getFullYear();

                if (!articlesByYearsMap.has(year)) {
                    articlesByYearsMap.set(year, []);
                }

                articlesByYearsMap.get(year).push(node.node);
            });
        }

        [...articlesByYearsMap.entries()].map((entry) => {
            const year = entry[0];
            const arr = entry[1];

            arr.sort((a, b) => {
                return new Date(b.created) - new Date(a.created);
            });

            articlesByYearsMap.set(year, arr);
        });

        articlesByYearsMap = new Map(
            [...articlesByYearsMap.entries()].sort((a, b) => b[0] - a[0])
        );
    } else {
        if (searchQuery !== "") {
            articlesByYearsMap.set(0, []);

            // Sort by most popular
            searchResults.forEach((node: any) => {
                articlesByYearsMap.get(0).push(node);
            });

            articlesByYearsMap.get(0).sort(function (a: any, b: any) {
                return views[b.slug] - views[a.slug];
            });
        } else {
            articlesByYearsMap.set(0, []);

            // Sort by most popular
            data.allGraphCmsArticle.edges.forEach((node: any) => {
                articlesByYearsMap.get(0).push(node.node);
            });

            articlesByYearsMap.get(0).sort(function (a: any, b: any) {
                return views[b.slug] - views[a.slug];
            });
        }
    }

    // TODO USE CALLBACK HOOK
    return (
        <>
            <Seo title="Blog" description="Articles about programming (Java, Node.js, JavaScript), reverse engineering (Retro Emulators), and cloud technologies." />
            <Breadcrumb activeLabel={"Blog"}/>
            <PageHeader
                title="Blog"
                periodColor={generateRandomColor(0)}
                description="Write-ups about the computer science space"
            />

            <div className="mx-auto mb-10">
                <div className="max-w-xs w-full sm:w-auto border-2 rounded-full px-1 py-1 mx-auto">
                    <button
                        className={clsx(
                            "w-1/2 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold transition duration-300",
                            sortByLatest
                                ? "bg-blue-600 text-white dark:text-gray-200"
                                : "text-gray-700 dark:text-gray-300 hover:bg-blue-200 dark:hover:bg-blue-500"
                        )}
                        onClick={() => setSortByLatest(true)}
                    >
                        Latest
                    </button>
                    <button
                        className={clsx(
                            "w-1/2 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold transition duration-300",
                            !sortByLatest
                                ? "bg-blue-600 text-white dark:text-gray-200"
                                : "text-gray-700 dark:text-gray-300 hover:bg-blue-200 dark:hover:bg-blue-500"
                        )}
                        onClick={() => setSortByLatest(false)}
                    >
                        Popular
                    </button>
                </div>

                <div className="mt-6 max-w-md mx-auto px-5">
                    <SearchBar
                        value={searchQuery}
                        onChange={(val) => {
                            setSearchQueryWrapper(val);
                           // setSearchQuery(val);
                        }}
                        onBlur={(val) => {
                            if (searchQuery && searchQuery.trim().length > 0) {
                                useTrackSearch(searchQuery);
                            }
                        }}
                    />
                </div>
                {sortByLatest ? (
                    Array.from(articlesByYearsMap.entries()).map((entry, index) => {
                        const [key, value] = entry;
                        return (
                            <section
                                className={clsx(
                                    "blog-article pt-10 mx-auto max-w-6xl px-5",
                                    index === articlesByYearsMap.size - 1 && "pb-16"
                                )}
                                key={key}
                            >
                                <h2 className="pb-2 mb-12 text-2xl font-extrabold leading-tight border-b border-gray-200 md:text-4xl dark:text-gray-300">
                                    {key}
                                </h2>

                                <nav className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10">
                                    {value.map((article: Article) => {
                                        // value.sort((a, b) => {
                                        //     console.log(new Date(a.created));
                                        //     console.log(new Date(b.created));
                                        //
                                        //     console.log(new Date(a.created) < new Date(b.created));
                                        //     console.log("======================================");
                                        //     return new Date(a.created) < new Date(b.created);
                                        // });

                                        if (isLoading) {
                                            article.views = 0;
                                        } else {
                                            article.views = views[article.slug];
                                        }

                                        return (
                                            <ArticleBlock
                                                key={article.slug}
                                                article={article}
                                                searchQuery={searchQuery}
                                            />
                                        );
                                    })}
                                </nav>
                            </section>
                        );
                    })
                ) : (
                    <section
                        className={clsx(
                            "blog-article pt-10 mx-auto max-w-6xl px-5"
                        )}
                    >
                        <h2 className="pb-2 mb-12 text-2xl font-extrabold leading-tight border-b border-gray-200 md:text-4xl dark:text-gray-300">
                            Most Popular
                        </h2>

                        <nav className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10">
                            {articlesByYearsMap.get(0).map((article: Article) => {
                                article.views = views[article.slug];
                                return (
                                    <ArticleBlock article={article} searchQuery={searchQuery}/>
                                );
                            })}
                        </nav>
                    </section>
                )}
            </div>
        </>
    );
};

export default Blog;
